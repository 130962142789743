import revive_payload_client_7ZegqPtHYH from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CqwwWsk7ek from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aKPp1smu7F from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TTWYUrC0yt from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-site-config@3.1.6_magicast@0.3.5_vue@3.5.13_typescript@5.7.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_buZxGZAwn7 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UUmkBB70EK from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bdVmEnyFpG from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_gtol3Dplqn from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_B33Q3qC4jv from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.35.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/uroweb/uroweb-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_hKYLskECYj from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_UjddzWUoSi from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1_rollup@4.35.0_typescript@5.7.2/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_XsnCB8UU0a from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.10.0_magicast@0.3.5_nuxt@3.15.0_@parcel+watch_fjrxf5axx5szy4qmttyvxtjgze/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_oUTllvrSTs from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.35.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_gCbufjRIPX from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.35.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import globals_qEnHRCYwqu from "/builds/uroweb/uroweb-frontend/plugins/globals.ts";
import gtm_cXsUZobmSF from "/builds/uroweb/uroweb-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_7ZegqPtHYH,
  unhead_CqwwWsk7ek,
  router_aKPp1smu7F,
  _0_siteConfig_TTWYUrC0yt,
  payload_client_buZxGZAwn7,
  navigation_repaint_client_UUmkBB70EK,
  check_outdated_build_client_bdVmEnyFpG,
  chunk_reload_client_gtol3Dplqn,
  plugin_vue3_B33Q3qC4jv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_hKYLskECYj,
  plugin_UjddzWUoSi,
  plugin_XsnCB8UU0a,
  plugin_oUTllvrSTs,
  nuxt_plugin_gCbufjRIPX,
  globals_qEnHRCYwqu,
  gtm_cXsUZobmSF
]
import validate from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirects_45global from "/builds/uroweb/uroweb-frontend/layers/redirects/middleware/redirects.global.ts";
import manifest_45route_45rule from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@20.17.11_db0@0.2.4_eslint@8.57.1_ioredis@5.4.2__hecb5wk6ow3qlidqin75q74jre/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1_rollup@4.35.0_typescript@5.7.2/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}